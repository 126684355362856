import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import { all, call, put, select } from 'redux-saga/effects';

import { SortOrder } from 'constants/list';
import { DEFAULT_TIMEZONE_CODE } from 'constants/time';
import { AssetTypeCode } from 'types/models/asset-type';
import { PowerMeterSample } from 'types/models/power-meter';
import Sample, { ExtendedSample, SampleDate } from 'types/models/sample';
import SamplePoint, { SamplePointLastSampleData } from 'types/models/samplePoint';
import getOriginalSamplePointIdsFromMergedSamplePoint from 'utils/associated-sample-points/get-original-sample-point-ids-from-merged-sample-point';
import { mergePowerMeterSamplesNewestFirst } from 'utils/associated-sample-points/merge-power-meter-samples';
import { mergeSafetyCheckInSamplesChronologically } from 'utils/associated-sample-points/merge-safety-check-in-sos';
import { mergeSoilMoistureAndTempSamples } from 'utils/associated-sample-points/merge-soil-moisture-temperature';
import { getRequest } from 'utils/redux-saga-requests';

import { parseSamples } from './utils';
import { selectCurrentEnterpriseCountry } from '../../enterprise/selectors';
import { makeSelectSamplePointById } from '../../samplePoints/selectors';
import {
  loadSamplePointSamples,
  loadSamplePointSamplesFailure,
  loadSamplePointSamplesSuccess,
  setPowerMeterSamples,
  setSamplePointSamples
} from '../actions';

function mergeSamplesIntoMainSamplePoint(
  assetTypeId: AssetTypeCode,
  samplesFromAssociatedSamplePoints: Sample[][]
): Sample[] | ExtendedSample[] {
  switch (assetTypeId) {
    case AssetTypeCode.SOIL: {
      const [moistureSamples, tempSamples] = samplesFromAssociatedSamplePoints;
      return moistureSamples.map((moistureSample: Sample, index: number) => moistureSample && tempSamples[index]
        ? mergeSoilMoistureAndTempSamples(
          moistureSample as unknown as SamplePointLastSampleData,
          tempSamples[index] as unknown as SamplePointLastSampleData
        ) : moistureSample
      ) as unknown as Sample[];
    }
    case AssetTypeCode.SAFETY_CHECK_IN: {
      const [checkInSamples, sosSamples] = samplesFromAssociatedSamplePoints;
      return mergeSafetyCheckInSamplesChronologically(
        checkInSamples as ExtendedSample[],
        sosSamples as ExtendedSample[]
      );
    }
    case AssetTypeCode.POWER_METER:
      return []; // Handled in its own way, not conforming to the Sample[] return type
    case AssetTypeCode.WATER_TANK:
    case AssetTypeCode.RAIN_GAUGE:
    case AssetTypeCode.FUEL_TANK:
    case AssetTypeCode.PIPE:
    case AssetTypeCode.TROUGH:
    case AssetTypeCode.LINE_PRESSURE:
    default:
      return samplesFromAssociatedSamplePoints[0];
  }
}

export function* requestSamples(
  action: ReturnType<typeof loadSamplePointSamples>
) {
  const {
    payload: { samplePointId, startDateMs, endDateMs, withErrorSamples, onSuccess }
  } = action;

  try {
    // Due to the refactor of sample points, the 'samplePointId' of a sensor may
    // change. Identical sample points now has an 'sid' field by which we
    // traverse to view the historical samples of a sensor. This means that the
    // list of samples returned via this endpoint may have 'samplePointId's that
    // don't match the one given in the endpoint. This can cause issues when
    // trying to view a sample point's data. To solve this issue, we set the
    // samplePointId of each sample returned in the response to match the
    // samplePointId given in the request. In the future, if it becomes
    // necessary to expose this linked list of sample points to the user, or
    // to the back office, this logic may need to change.
    const country: string = yield select(selectCurrentEnterpriseCountry);
    const samplePoint: SamplePoint = yield select(
      makeSelectSamplePointById(samplePointId)
    );

    // If for some reason the samplepoint doesn't exist, return empty.
    if (!samplePoint) {
      return all([
        put(loadSamplePointSamplesSuccess([])),
        put(setSamplePointSamples([]))
      ]);
    }

    const timezoneCode = samplePoint.siteTimezoneCode || DEFAULT_TIMEZONE_CODE;
    const startDateInSiteTimezone = moment(startDateMs).tz(timezoneCode);
    const endDateInSiteTimezone = moment(endDateMs).tz(timezoneCode);

    const requestSamplePointIds = getOriginalSamplePointIdsFromMergedSamplePoint(samplePoint);

    const responses: AxiosResponse[] = yield all(
      requestSamplePointIds.map((id) => call(getRequest, `samplepoint/${id}/sample`, {
        params: {
          startDate: startDateInSiteTimezone.format(),
          endDate: endDateInSiteTimezone.format(),
          withErrorSamples,
          orderBy: SortOrder.DESC
        }
      }))
    );

    if (samplePoint.assetTypeId === AssetTypeCode.POWER_METER) {
      const powerMeterSamplesNewestFirst: Map<SampleDate, PowerMeterSample> = mergePowerMeterSamplesNewestFirst(
        requestSamplePointIds,
        responses.map((response) => response.data),
        samplePoint
      );
      yield all([
        put(loadSamplePointSamplesSuccess(responses)),
        put(setPowerMeterSamples(Object.fromEntries(powerMeterSamplesNewestFirst)))
      ]);
    } else {
      const samples = mergeSamplesIntoMainSamplePoint(
        samplePoint.assetTypeId,
        responses.map((response) => response.data)
      );
      const parsedSamples = parseSamples({
        samples,
        samplePoint,
        country
      });
      yield all([
        put(loadSamplePointSamplesSuccess(responses)),
        put(setSamplePointSamples(keyBy(parsedSamples, 'id')))
      ]);
    }

    if (onSuccess) onSuccess();
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    let message;
    if (error.message === 'Network Error') {
      message = 'Network Error';
    } else {
      message = get(
        error,
        'response.data.message',
        'Sorry, something went wrong.'
      );
    }

    yield put(loadSamplePointSamplesFailure(message, error));
  }
}