export enum TriggerType {
  BATTERY_LOW = 'battery-low',
  WATER_TANK_HIGH = 'watertank-high',
  WATER_TANK_LOW = 'watertank-low',
  WATER_TANK_RATE_OF_FALL = 'watertank-rate-of-fall',
  PIPE_FLOW_RATE_HIGH = 'pipe-flow-rate-high',
  PIPE_FLOW_RATE_LOW = 'pipe-flow-rate-low',
  PIPE_VOLUME_ROLLING_WINDOW_HIGH = 'pipe-volume-rolling-window-high',
  PIPE_VOLUME_ROLLING_WINDOW_LOW = 'pipe-volume-rolling-window-low',
  POWER_METER_LINE_MISSING = 'power-meter-line-missing',
  POWER_METER_OVER_VOLTAGE = 'power-meter-over-voltage',
  POWER_METER_UNDER_VOLTAGE = 'power-meter-under-voltage',
  POWER_METER_LINE_VOLTAGE_MISMATCH = 'power-meter-line-voltage-mismatch',
  POWER_METER_LINE_CURRENT_MISMATCH = 'power-meter-line-current-mismatch',
  FUEL_LOW = 'fuel-low',
  FUEL_RATE_OF_FALL = 'fuel-rate-of-fall',
  LINE_PRESSURE_HIGH = 'line-pressure-high',
  LINE_PRESSURE_LOW = 'line-pressure-low',
  DAM_HIGH = 'dam-high',
  DAM_LOW = 'dam-low',
  DAM_RATE_OF_FALL = 'dam-rate-of-fall',
  BORE_HIGH = 'bore-high',
  BORE_LOW = 'bore-low',
  LIQUID_FERTILISER_LOW = 'liquid-fertiliser-low',
  LIQUID_FERTILISER_RATE_OF_FALL = 'liquid-fertiliser-rate-of-fall',
  LIQUID_FERTILISER_FLOW_RATE_HIGH = 'liquid-fertiliser-flow-rate-high', // only applicable to liquid fert with forecast
  LIQUID_FERTILISER_FLOW_RATE_LOW = 'liquid-fertiliser-flow-rate-low', // only applicable to liquid fert with forecast
  SENSOR_MOVED = 'sensor-moved',
  TROUGH_AVG_HIGH = 'trough-avg-high',
  TROUGH_AVG_LOW = 'trough-avg-low',
  TROUGH_HIGH = 'trough-high', // trough direct only
  TROUGH_LOW = 'trough-low' // trough direct only
}

export enum EventLevelColor {
  'rgb(33, 150, 243)' = 1,
  'rgb(255, 193, 7)' = 2,
  'rgb(255, 152, 0)' = 3,
  'rgb(244, 67, 54)' = 4
}

/**
 * Trigger unit type
 * BE doesn't read ValueType, so for some triggers FE set ValeType to a not
 * very reasonable value. For example, FE chooses to use LEVEL for pipe flow
 * rate triggers.
 */
export enum ValueType {
  VALUE = 1,
  RATE = 2,
  VOLUME = 3,
  REDUCED_LEVEL = 4,
  PERCENTAGE = 5
}

export enum ConditionType {
  GREATER = 1,
  GREATER_EQUALS = 2,
  LOWER = 3,
  LOWER_EQUALS = 4,
  EQUALS = 5
}

export enum EventLevel {
  INFO = 1,
  WARNING = 2,
  CRITICAL = 4
}

export enum TriggerMessage {
  TANK_HIGH = 'Tank Full/Overfull',
  TANK_LOW = 'Tank Low',
  EXCESSIVE_RATE_OF_FALL = 'Rate of Excessive Fall',
  FLOW_RATE_HIGH = 'Flow Rate High',
  FLOW_RATE_LOW = 'Flow Rate Low',
  PIPE_VOLUME_ROLLING_WINDOW_HIGH = 'Pipe Volume High',
  PIPE_VOLUME_ROLLING_WINDOW_LOW = 'Pipe Volume Low',
  VOLUME_HIGH = 'Volume High',
  VOLUME_LOW = 'Volume Low',
  AVERAGE_LEVEL_HIGH = 'Average Level High',
  AVERAGE_LEVEL_LOW = 'Average Level Low',
  LINE_PRESSURE_HIGH = 'Pressure Level High',
  LINE_PRESSURE_LOW = 'Pressure Level Low',
  DAM_HIGH = 'Dam High',
  DAM_LOW = 'Dam Low',
  BORE_LEVEL_HIGH = 'Bore Level High',
  BORE_LEVEL_LOW = 'Bore Level Low',
  TROUGH_HIGH = 'Trough High',
  TROUGH_LOW = 'Trough Low'
}