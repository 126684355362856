import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';

export const SymbolToUnit = {
  cm: {
    name: {
      singular: 'Centimetre',
      plural: 'Centimetres'
    }
  },
  in: {
    name: {
      singular: 'Inch',
      plural: 'Inches'
    }
  },
  m: {
    name: {
      singular: 'Metre',
      plural: 'Metres'
    }
  },
  ft: {
    name: {
      singular: 'Foot',
      plural: 'Feet'
    }
  }
};

export enum UnitType {
  /**
   * cm or in
   */
  LEVEL_SM = 'LEVEL_SM',
  /**
   * metre or foot.
   * We don't name it as LEVEL_M as M could be mistaken for 'metre'.
   */
  LEVEL_MD = 'LEVEL_MD',
  /** flow per minute */
  FLOW = 'FLOW',
  /** flow per hour */
  FLOW_HOURLY = 'FLOW_HOURLY',
  /** litre or gallon */
  VOLUME_SM = 'VOLUME_SM',
  /** kilolitre or cubic-foot */
  VOLUME_MD = 'VOLUME_MD',
  /**
   * megalitre or acre-foot.
   * We don't name it as VOLUME_L as L could be mistaken for 'litre'.
   */
  VOLUME_LG = 'VOLUME_LG',
  /** cm/h or in/h */
  TREND_SM = 'TREND_SM',
  /** metre/h or ft/h */
  TREND_MD = 'TREND_MD',
  RAIN = 'RAIN',
  SIZE = 'SIZE',
  PRESSURE = 'PRESSURE',
  AREA_SM = 'AREA_SM',
  MATTER = 'MATTER'
}

interface GetUnitByCountryParams {
  unitType: UnitType;
  country: string;
}

type UnitFlowMinute = 'L/min' | 'gal/min';
type UnitFlowHour = 'L/hr' | 'gal/hr';
type UnitTrendMd = 'm/h' | 'ft/h';
type UnitTrendSm = 'cm/h' | 'in/h';
export type UnitAreaSm = 'm²' | 'ft²';
export type UnitLevelMd = 'm' | 'ft';
export type UnitLevelSm = 'cm' | 'in';
export type UnitMatter = 'kg/ha' | 'lb/ac';
export type UnitPressure = 'kPa' | 'psi';
export type UnitRain = 'mm' | 'in';
export type UnitSize = 'ha' | 'ac';
export type UnitVolumeLg = 'ML' | 'ac. ft.';
export type UnitVolumeMd = 'KL' | 'ft³';
export type UnitVolumeSm = 'L' | 'gal';

export type UnitSymbol =
  | UnitLevelSm
  | UnitLevelMd
  | UnitFlowMinute
  | UnitFlowHour
  | UnitVolumeSm
  | UnitVolumeMd
  | UnitVolumeLg
  | UnitTrendSm
  | UnitTrendMd
  | UnitRain
  | UnitSize
  | UnitPressure
  | UnitAreaSm
  | UnitMatter
  | '%';

/**
 * If getting unit in a React component, please use useGetLocaleUnit instead.
 * @returns Unit symbol
 */
export function getUnitSymbolByCountry<S extends UnitSymbol>({
  unitType,
  country
}: GetUnitByCountryParams): S {
  const isUS = country === 'USA';
  // @uom-refactor to return a unit object which contains
  // both short & long name and symbol etc. And the unit can be used as a
  // type-safe parameter to construct a quantity object.
  switch (unitType) {
    case UnitType.LEVEL_SM:
      return (isUS ? 'in' : 'cm') as S;
    case UnitType.LEVEL_MD:
      return (isUS ? 'ft' : 'm') as S;
    case UnitType.FLOW:
      return (isUS ? 'gal/min' : 'L/min') as S;
    case UnitType.FLOW_HOURLY:
      return (isUS ? 'gal/hr' : 'L/hr') as S;
    case UnitType.VOLUME_SM:
      return (isUS ? 'gal' : 'L') as S;
    case UnitType.VOLUME_MD:
      return (isUS ? 'ft³' : 'KL') as S;
    case UnitType.VOLUME_LG:
      return (isUS ? 'ac. ft.' : 'ML') as S;
    case UnitType.TREND_SM:
      return (isUS ? 'in/h' : 'cm/h') as S;
    case UnitType.TREND_MD:
      return (isUS ? 'ft/h' : 'm/h') as S;
    case UnitType.RAIN:
      return (isUS ? 'in' : 'mm') as S;
    case UnitType.SIZE:
      return (isUS ? 'ac' : 'ha') as S;
    case UnitType.PRESSURE:
      return (isUS ? 'psi' : 'kPa') as S;
    case UnitType.AREA_SM:
      return (isUS ? 'ft²' : 'm²') as S;
    case UnitType.MATTER:
      return (isUS ? 'lb/ac' : 'kg/ha') as S;
    default:
      return 'cm' as S;
  }
}

export function useGetLocaleUnitSymbol<S extends UnitSymbol>(unitType: UnitType): S {
  const country = useEnterpriseCountry();
  return getUnitSymbolByCountry<S>({ unitType, country });
}
