export const SEARCH_PARAMS = {
  CIBO_LOCATION_ID: 'ciboLocationId',
  CHART: {
    DATE_RANGE_TYPE: 'dateRangeType',
    END_DATE: 'endDate'
  },
  TABLE: {
    CURRENT_PAGE: 'currentPage',
    SORT_FIELD: 'sortField',
    SORT_ORDER: 'sortOrder'
  },
  DRAWER: {
    key: 'drawer',
    values: {
      CIBO_LOCATION_DETAILS: 'ciboLocationDetails',
      CONFIGURE_SAMPLE_POINT: 'configureSamplePoint',
      IP_CAMERA_DETAILS: 'ipCameraDetails',
      SAMPLE_POINT_DETAILS: 'samplePointDetails'
    }
  },
  DRAWER_INDEX: 'drawerIndex',
  EVENT_ID: 'eventId',
  PLAY_EVENT_AT_TIME: 'playEventAtTime',
  LAYER: {
    key: 'layer',
    values: {
      CIBO: 'cibo'
    }
  },
  MAP: {
    LAT: 'lat',
    LNG: 'lng',
    ZOOM: 'zoom'
  },
  SEARCH: 'search',
  SITE_ID: 'siteId',
  TAB: 'tab',
  UTM_TERM: 'utm_term'
};